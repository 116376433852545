















































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  setup(_, { root }) {
    const state = reactive({
      loading: false,
      servicesAdded: false,
      error: 200 as number,
    });

    const model = reactive({
      services: [] as any,
    });

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      axiosInstance
        .get(`event/${root.$route.params.id}/company-additive-template`)
        .then(({ data: { templates } }) => {
          model.services = templates;
          state.servicesAdded = templates && templates.length ? true : false;
        })
        .catch((e) => console.log(e));
    };

    onMounted(fetchData);

    const addService = () => {
      model.services.push({
        name: "",
        unit: "",
        price: 0,
        hasQuantity: true,
      });
    };

    const removeService = (index: number) => {
      model.services.splice(index, 1);
    };

    const saveServices = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      if (state.servicesAdded) {
        axiosInstance
          .put(
            `event/${root.$route.params.id}/company-additive-template`,
            model.services,
            {
              headers: {
                event: root.$route.params.id,
              },
            }
          )
          .then(() => {
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "success",
              message: root.$tc("panel.event.company.services.success"),
            });
            fetchData;
          })
          .catch((error) => {
            state.error = error.response.status;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "error",
              message: getErrorMessage(state.error),
            });
          });
      } else {
        axiosInstance
          .post(
            `event/${root.$route.params.id}/company-additive-template`,
            model.services,
            {
              headers: {
                event: root.$route.params.id,
              },
            }
          )
          .then(() => {
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "success",
              message: root.$tc("panel.event.company.services.success"),
            });
            fetchData;
          })
          .catch((error) => {
            state.error = error.response.status;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "error",
              message: getErrorMessage(state.error),
            });
          });
      }
    };

    return { state, model, addService, removeService, saveServices };
  },
});
